import React from "react";

import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import ValuePropPortfolio from "../../components/portfolio/ValuePropPortfolio";
import ProductBenefitsPortfolio from "../../components/portfolio/ProductBenefitsPortfolio";

const PortfolioPage = () => (
  <Layout>
    <SEO title="Développement d'applications web et mobiles" />
    <ValuePropPortfolio />
    <ProductBenefitsPortfolio />
  </Layout>
);

export default PortfolioPage;