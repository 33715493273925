import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"
import BorderButton from "../BorderButton"

const ProductBenefitsPortfolio = props => {

  return (
    <section className="product-benefits-portfolio">
      <div className="product-benefits-group-portfolio">
        <div className="product-benefit-1-tagline-portfolio">
          <h3>AtelierNum</h3>
          <p>Atelier num’ est une plateforme permettant de former les usagers lors des ateliers numériques, grâce à des exercices adaptés à leurs niveaux !</p>
          <a href="https://www.toolpad.fr/atelierNum.html" target="_blank"><BorderButton label="Découvrir"></BorderButton></a>
        </div>
        <div className="product-benefit-1-image-portfolio">
          <StaticImage src="../../assets/portfolio/atelierNum.png" alt="Atelier Num'" className="portfolio-1-photo" placeholder ="blurred"/>
        </div>
        <div className="product-benefit-2-tagline-portfolio">
          <h3>Visite +</h3>
          <p>Les visites de vos lieux culturels sont esentiels pour faire vivre l'histoire du patrimoine. Allez plus loin et partagez encore + de connaissances à vos visiteurs.</p>
          <a href="https://www.visiteplus.fr/" target="_blank"><BorderButton label="Découvrir"></BorderButton></a>        
        </div>
        <div className="product-benefit-2-image-portfolio">
          <StaticImage src="../../assets/portfolio/visite-plus.png" alt="Visite+" className="portfolio-2-photo" placeholder ="blurred"/>
        </div>
        <div className="product-benefit-3-tagline-portfolio">
          <h3>Pupylle</h3>
          <p>Proposez à vos apprenants des activités ludiques et pédagogiques pour découvrir la culture française et apprendre la langue.</p>
          <a href="https://www.pupylle.fr/" target="_blank"><BorderButton label="Découvrir"></BorderButton></a>
        </div>
        <div className="product-benefit-3-image-portfolio">
          <StaticImage src="../../assets/portfolio/pupylle.png" alt="Pupylle" className="portfolio-3-photo" placeholder ="blurred"/>
        </div>
        <div className="product-benefit-4-tagline-portfolio">
          <h3>AutoCut</h3>
          <p>AutoCut utilise des algorithmes de detection de silences afin de réaliser des cuts automatiquement autour des silences.</p>
          <a href="https://www.autocut.fr/" target="_blank"><BorderButton label="Découvrir"></BorderButton></a>
        </div>
        <div className="product-benefit-4-image-portfolio">     
          <StaticImage src="../../assets/portfolio/autocut.png" alt="AutoCut" className="portfolio-4-photo" placeholder ="blurred"/>
        </div>
      </div>
    </section>
  )
};

export default ProductBenefitsPortfolio;
