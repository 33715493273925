import React from 'react';
import { Link } from 'gatsby';

const ValuePropPortfolio = props => {

  return (
    <section className="value-prop-portfolio">
      <div className="value-prop-content-portfolio">
        <div className="main-message-portfolio">
          <p className="main-abovetitle-portfolio"><Link to="/" className="main-abovetitle-portfolio">Accueil</Link> &#62; Portoflio</p>
          <h1>Portfolio</h1>
          <p className="main-subtitle-portfolio">Nous avons réalisé aussi bien des applications web (SaaS) que des applications mobiles. Nous avons également réalisés des sites ultra performant en terme de SEO (100% Ligthouse).</p>
        </div>
      </div>
    </section>
  )
};

export default ValuePropPortfolio;
